$Panel-double-border-space: -12px;
.Panel:before {
  box-shadow: var(--panel-box-shadow);
  content: "";
  position: absolute;
  top: $Panel-double-border-space ;
  left: $Panel-double-border-space ;
  right:$Panel-double-border-space ;
  bottom: $Panel-double-border-space ;
  z-index: -100;
  background-color:var(--panel-bg-color);
  border: var(--double-border-width) solid var(--panel-border-color);
  border-radius:  var(--border-radius);
}




@import "variables";
@import "Grained";
@import "fonts";
@import "main";
@import "Menu";
@import "Panel";
@import "KeyValueDisplay";
@import "BcConnect";
@import "Mint";
@import "Notification";
@import "rainbowkit";
@import "Progress";
@import "Dialog";


.p-selectbutton {
  .p-button {
    background: #0000AA;
    border: 1px solid var(--dapp-color-light);
    color: var(--dapp-color-light);

    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    padding: 0.35rem;

    &.p-highlight {
      color: var(--dapp-tonic-color);
    }

  }

}

.p-dropdown{
  background: #0000AA;
  padding-right: .5rem;
  border: 1px solid #707070;
}

.p-button:focus{
  color: var(--dapp-tonic-color);
}

.p-dropdown-item{
  background-color: #0000AA;
  color : var(--dapp-color-light);
  padding: .25rem;
}

.p-dropdown-item:hover{
  background-color: var(--bg-color-hover);
}

.title {
  font-size: 1.25rem;
  color : var(--tonic-color);
  background-color: var(--tonic-bg-color);
}

.roi {
  font-size: 1.1rem;
  color : var(--dapp-color-light);  
  background: #0000AA;
}


.Btn-small-action {
  background: transparent;
  color:  var(--dapp-tonic-color);
  font-size: .9rem;
  border-radius: 3px;
  padding: .25rem ;
  margin: .1rem;
}



