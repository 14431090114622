

:root {

    --panel-boder-color: #fff;
    --panel-apr-bg-color: #0000AA;
    --button-border: none;
    --button-radius: 0;
    --button-before:"";
    --button-bg: none;
    --button-color: #ffff00;
    --button-active-bg-color: #fe0000;
    --panel-bg-color: #000;
    --panel-border-color: #fff;
    --bg-color: #0000fc;
    --bg-color-hover: rgb(0, 0, 117);
    --tonic-color: #ffff00;
    --tonic-bg-color: #fe0000;
    --tonic-bg-color-quiet: rgba(170, 0, 0, 0.5);
    --bg-form-active: #BFBFBF;
    --bg-form-disabled: #606060;
    --quiet-font-color: #fff;
    --double-border-width: .21rem;
    --border-radius: 0;
    --bg-apr-color: #0000fc;
    --panel-box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 12px 12px;
    --white-box-shadow: rgba(255, 255,255, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 12px 12px;
//--panel-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(1px, 1px, 1px, 0.3) 0px 8px 16px -8px;
    --menu-selected-color: #fff;
    --menu-color:#fff;
    --color-error: #bb0909;
    --color-warn: #f98500;
    --color-success: #109811;
    --dapp-color-light: #06EBFC;
    --dapp-tonic-color: #ffff00;


}